import React, { Component } from "react";
import AU_flag from "../../../../images/AU-flag.svg";
import NZ_flag from "../../../../images/NZ-flag.svg";
import USA_flag from "../../../../images/USA-flag.svg";
import UK_flag from "../../../../images/UK-flag.svg";
import "./styles.scss";

const countryCodeOptions = [
  {
    label: "AU (+61)",
    value: "61",
    flag: AU_flag,
    hint:
      "Australian mobile numbers must be 9 digits starting with 4 (e.g., 412345678).",
  },
  {
    label: "NZ (+64)",
    value: "64",
    flag: NZ_flag,
    hint:
      "New Zealand mobile numbers must be 8-9 digits starting with 2 (e.g., 21234567).",
  },
  {
    label: "US (+1)",
    value: "1",
    flag: USA_flag,
    hint:
      "US mobile numbers must be 10 digits starting with an area code from 2-9 (e.g., 2015550123).",
  },
  {
    label: "UK (+44)",
    value: "44",
    flag: UK_flag,
    hint:
      "UK mobile numbers must be 10 digits starting with 7 followed by 4-9 (e.g., 7123456789).",
  },
];

class PhoneNumberInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedCountry: countryCodeOptions[0],
      isError: false,
    };
  }

  componentDidMount() {
    this.getSelectedCountryCode();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { setError } = this.props;
    const { selectedCountry } = this.state;
    const { value } = this.props;

    if (selectedCountry && prevProps && prevProps.value !== value) {
      const AU_Regex = /^4\d{8}$/;
      const NZ_Regex = /^2\d{7,8}$/;
      const USA_Regex = /^[2-9]\d{2}\d{7}$/;
      const UK_Regex = /^7[4-9]\d{8}$/;

      if (selectedCountry.value === "61") {
        if (!AU_Regex.test(value)) {
          setError(true);
          this.setState({ isError: true });
        } else {
          setError(false);
          this.setState({ isError: false });
        }
      } else if (selectedCountry.value === "64") {
        if (!NZ_Regex.test(value)) {
          setError(true);
          this.setState({ isError: true });
        } else {
          setError(false);
          this.setState({ isError: false });
        }
      } else if (selectedCountry.value === "1") {
        if (!USA_Regex.test(value)) {
          setError(true);
          this.setState({ isError: true });
        } else {
          setError(false);
          this.setState({ isError: false });
        }
      } else if (selectedCountry.value === "44") {
        if (!UK_Regex.test(value)) {
          setError(true);
          this.setState({ isError: true });
        } else {
          setError(false);
          this.setState({ isError: false });
        }
      }
    }

    if (prevState.selectedCountry.value !== selectedCountry.value) {
      this.getSelectedCountryCode();
    }
  }

  handleSelectCountry = (option) => {
    this.setState({ selectedCountry: option, isOpen: false });
  };

  getSelectedCountryCode = () => {
    const { onChangeCountryCode } = this.props;
    const { selectedCountry } = this.state;

    if (selectedCountry && selectedCountry.value) {
      onChangeCountryCode(selectedCountry.value);
    }
  };

  toggleDropdown = (e) => {
    e.preventDefault();
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const {
      id,
      label,
      type,
      placeholder = "",
      value,
      onChange,
      className = "",
      fullWidth,
    } = this.props;
    const { isOpen, selectedCountry, isError } = this.state;

    return (
      <div
        className={`${fullWidth && "w-100"}`}
        style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}
        >
          <div
            className={`input-field outlined`}
            style={{
              width: "45%",
            }}
          >
            <div
              className="w-100"
              style={{
                height: "100%",
                position: "relative",
                backgroundColor: "#f3f3f3",
                border: "none",
                borderRadius: "4px",
                padding: "9px 30px 9px 12px",
                boxSizing: "border-box",
                margin: "0px",
              }}
            >
              {/* Selected Option */}
              <div
                onClick={this.toggleDropdown}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  columnGap: "10px",
                  cursor: "pointer",
                  color: !selectedCountry && "#9e9e9e",
                  borderRadius: "4px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {selectedCountry && (
                  <img src={selectedCountry.flag} alt="selected-flag" />
                )}

                {selectedCountry && selectedCountry.label
                  ? selectedCountry.label
                  : "Select an option"}
              </div>

              {/* Dropdown Options */}
              {isOpen && (
                <ul
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    margin: "0",
                    padding: "0",
                    listStyleType: "none",
                    borderRadius: "8px",
                    backgroundColor: "#f3f3f3",
                    boxShadow:
                      "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
                    zIndex: 1000,
                  }}
                >
                  {countryCodeOptions.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => this.handleSelectCountry(option)}
                      style={{
                        padding: "9px 12px",
                        cursor: "pointer",
                        backgroundColor: "#f3f3f3",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        columnGap: "10px",
                        borderRadius: 8,
                      }}
                    >
                      <img src={option.flag} alt="country" />
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}

              <button
                style={{ position: "absolute", top: "2px", right: "5px" }}
                onClick={this.toggleDropdown}
              >
                {isOpen ? (
                  <i className="material-icons">keyboard_arrow_up</i>
                ) : (
                  <i className="material-icons">keyboard_arrow_down</i>
                )}
              </button>
            </div>
          </div>

          <div
            className={`input-field outlined`}
            style={{
              width: "55%",
            }}
          >
            <input
              id={id || "outlined-input"}
              className={`outlined-input-field ${
                isError ? "outlined-input-field--error" : ""
              } ${className}`}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              type={type}
            />

            {label && <label for="outlined-input">{label}</label>}
          </div>
        </div>

        {selectedCountry && (
          <div
            style={{ fontSize: "11px", color: isError ? "#EB2A31" : "#000000" }}
          >
            {selectedCountry.hint}
          </div>
        )}
      </div>
    );
  }
}

export default PhoneNumberInput;
