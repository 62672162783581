import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ls from "local-storage";
import axios from "axios";
import LogInWithMobileForm from "./LogInWithMobileForm";
import LogInWithEmailOrUsernameForm from "./LogInWithEmailOrUsernameForm";
import { api_token_validate } from "../../../config";
import "./styles.scss";

class Login extends Component {
  componentDidMount() {
    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("access_token");

    if (token) {
      this.verifyTokenFromQueryParams(token);
    }
  }

  verifyTokenFromQueryParams = async (token) => {
    const { history } = this.props;

    await axios
      .get(api_token_validate, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        ls.set("auth", { access_token: `Bearer ${token}` });
        history.push("/reportin");
      });
  };

  render() {
    const { updateMe } = this.props;

    return (
      <>
        <div className="login-page">
          <main className="login-page__container">
            <div className="header">
              <img
                src={require("../../../images/allwarden-logo.svg")}
                alt="logo"
              />

              <div className="header--text">Emergency Event Managements</div>
            </div>

            <LogInWithMobileForm />

            <div className="or">or</div>

            <LogInWithEmailOrUsernameForm updateMe={updateMe} />
          </main>

          <div className="login-page-footer">
            For authorised users of All Wardens.
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Login);
