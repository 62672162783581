import React, { Component } from "react";
import ls from "local-storage";
import axios from "axios";
import TextInput from "../../../UI/Form/TextInput";
import { api_email_login_url, forget_password_url } from "../../../../config";
import account_circle_icon from "../../../../images/account-circle.svg";
import password_icon from "../../../../images/password.svg";
import { LoginAlertModal } from "../LoginAlertModal";
import { api_user_url } from "../../../../config";
import "./styles.scss";

class LogInWithEmailOrUsernameForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailInput: "",
      passwordInput: "",
      loginAlert: {
        isOpen: false,
        message: "",
        variant: "", // success | error | warn
      },
    };
  }

  handleOpenAlertModal = (message, variant) => {
    this.setState({
      loginAlert: {
        isOpen: true,
        message,
        variant,
      },
    });
  };

  handleCloseAlertModal = () => {
    this.setState({
      loginAlert: {
        isOpen: false,
        message: "",
      },
    });
  };

  handleEmailInputChange = (e) => {
    const { value } = e.target;
    this.setState({
      emailInput: value,
    });
  };

  handlePasswordInputChange = (e) => {
    const { value } = e.target;
    this.setState({
      passwordInput: value,
    });
  };

  getUserData = () => {
    const { updateMe, history } = this.props;

    axios
      .get(api_user_url, {
        headers: {
          Authorization: ls.get("auth").access_token,
        },
      })
      .then((resp) => {
        const me = {
          firstname: resp.data.Firstname,
          lastname: resp.data.Lastname,
          email: resp.data.Email,
          mobile: resp.data.Mobile,
          client_master: resp.data.Is_Client_Master,
          client_uid: resp.data.Client_UID,
          primary_floor_uid: resp.data.Primary_Location_Floor_UID,
        };

        updateMe(me);

        history.push("/reportin");
      })
      .catch((err) => {
        this.handleOpenAlertModal(err.message, "error");
      });
  };

  handleSignIn = (e) => {
    e.preventDefault();
    const { emailInput, passwordInput } = this.state;
    const credentials = {
      email: emailInput,
      password: passwordInput,
    };

    if (emailInput.length === 0 && passwordInput.length > 0) {
      this.handleOpenAlertModal("Please input your email / username.", "error");
      return;
    } else if (emailInput.length > 0 && passwordInput.length === 0) {
      this.handleOpenAlertModal("Please input your password.", "error");
      return;
    } else if (emailInput.length === 0 && passwordInput.length === 0) {
      this.handleOpenAlertModal(
        "Please input your email / username and password.",
        "error"
      );
      return;
    }

    if (passwordInput.length < 6) {
      this.handleOpenAlertModal(
        "Password must be at least 6 characters long.",
        "error"
      );

      return;
    }

    axios
      .post(api_email_login_url, credentials)
      .then((resp) => {
        if (resp.data.error) {
          if (resp.data.error === "invalid_credentials") {
            this.handleOpenAlertModal(
              "Incorrect email / username and password.",
              "error"
            );
          } else if (resp.data.error === "unauthorized_access") {
            this.handleOpenAlertModal(
              "All Wardens is only available to authorised Wardens.",
              "warn"
            );
          } else {
            this.handleOpenAlertModal(resp.data.message, "error");
          }
        } else {
          let auth = {
            ...resp.data,
            access_token: resp.data.token_type + " " + resp.data.access_token,
          };
          ls.set("auth", auth);
          this.getUserData();
        }
      })
      .catch((err) => {
        this.handleOpenAlertModal(err, "error");
      });
  };

  render() {
    const { emailInput, passwordInput, loginAlert } = this.state;

    return (
      <>
        <form className="login-using-email-form" onSubmit={this.handleSignIn}>
          <div className="login-using-email-form__title">
            Sign in with Email / Username
          </div>

          <div className="login-using-email-form__input">
            <img src={account_circle_icon} alt="logo" />

            <TextInput
              name="email"
              placeholder="Email / Username"
              onChange={this.handleEmailInputChange}
              fullWidth
            />
          </div>

          <div className="login-using-email-form__input">
            <img src={password_icon} alt="logo" />

            <TextInput
              name="password"
              placeholder="Password"
              onChange={this.handlePasswordInputChange}
              type="password"
              fullWidth
            />
          </div>

          <button
            className="btn green--bg"
            disabled={Boolean(!emailInput || !passwordInput)}
            onClick={this.handleSignIn}
          >
            Sign in
          </button>

          <div className="login-using-email-form__forgot-password">
            <a
              href={forget_password_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Forgot Password
            </a>
          </div>
        </form>

        <LoginAlertModal
          isOpen={loginAlert.isOpen}
          message={loginAlert.message}
          variant={loginAlert.variant}
          onClose={this.handleCloseAlertModal}
        />
      </>
    );
  }
}

export default LogInWithEmailOrUsernameForm;
