import React, { Component, createRef } from "react";
import axios from "axios";
import PhoneNumberInput from "../../../UI/Form/PhoneNumberInput";
import sign_mobile_icon from "../../../../images/sign-mobile.svg";
import { LoginAlertModal } from "../LoginAlertModal";
import { api_mobile_number_login_url } from "../../../../config";
import "./styles.scss";

const lockDuration = 5 * 60 * 1000;

class LogInWithMobileForm extends Component {
  constructor(props) {
    super(props);

    this.mobileInputRef = createRef();

    this.state = {
      mobileInput: "",
      countryCode: "",
      isError: false,
      failedAttempts: 0,
      isLocked: false,
      lockStartTime: null,
      remainingTime: 0,
      loginAlert: {
        isOpen: false,
        message: "",
        variant: "", // success | error | warn
      },
    };

    this.timerInterval = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { lockStartTime } = this.state;
    const lockStartTimeOnLocalStorage = Number(
      localStorage.getItem("lockStartTime")
    );

    const elapsedTime = Date.now() - lockStartTimeOnLocalStorage;
    const remainingTime = lockDuration - Number(elapsedTime);

    // Detect lockStartTime on LocalStorage and put to React state
    if (
      lockStartTime === null &&
      lockStartTimeOnLocalStorage !== 0 &&
      remainingTime > 0 &&
      prevState.lockStartTime !== lockStartTimeOnLocalStorage
    ) {
      this.setState({
        lockStartTime: lockStartTimeOnLocalStorage,
        failedAttempts: 5,
        isLocked: true,
      });

      this.startLockTimer();
    }

    // Remove lockStartTime on LocalStorage when the calculated of remaining time is 0
    if (
      lockStartTime === null &&
      lockStartTimeOnLocalStorage !== 0 &&
      remainingTime <= 0
    ) {
      localStorage.removeItem("lockStartTime");
    }
  }

  componentWillUnmount() {
    // Clear interval when the component is unmounted
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  handleOpenAlertModal = (message, variant) => {
    this.setState({
      loginAlert: {
        isOpen: true,
        message,
        variant,
      },
    });
  };

  handleCloseAlertModal = () => {
    this.setState({
      loginAlert: {
        isOpen: false,
        message: "",
      },
    });
  };

  startLockTimer = () => {
    this.timerInterval = setInterval(() => {
      const elapsedTime = Date.now() - this.state.lockStartTime;
      const remainingTime = lockDuration - elapsedTime;

      if (remainingTime <= 0) {
        clearInterval(this.timerInterval);
        this.setState({
          isLocked: false,
          failedAttempts: 0,
          lockStartTime: null,
          remainingTime: 0,
        });
      } else {
        this.setState({ remainingTime });
      }
    }, 1000);
  };

  formatTime = (milliseconds) => {
    const seconds = Math.floor((milliseconds / 1000) % 60);
    const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
    return `${minutes} menit ${seconds} detik`;
  };

  formatMilliseconds = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  handleChange = (e) => {
    const { value } = e.target;

    this.setState({
      mobileInput: value,
    });
  };

  setSelectedCountryCode = (data) => {
    this.setState({ countryCode: data });
  };

  setError = (data) => {
    this.setState({ isError: data });
  };

  handleRequestLink = (e) => {
    e.preventDefault();
    const { countryCode, mobileInput, isLocked } = this.state;

    if (isLocked) {
      this.handleOpenAlertModal(
        "Too many failed attempts. Try again in 5 minutes.",
        "error"
      );

      return;
    }

    this.setState((prevState) => {
      const updatedFailedAttempts = prevState.failedAttempts + 1;

      if (updatedFailedAttempts >= 5) {
        this.startLockTimer();

        localStorage.setItem("lockStartTime", Date.now());

        return {
          failedAttempts: updatedFailedAttempts,
          isLocked: true,
          lockStartTime: Date.now(),
        };
      } else {
        return {
          failedAttempts: updatedFailedAttempts,
          isLocked: false,
        };
      }
    });

    axios
      .post(api_mobile_number_login_url, {
        phone: `${countryCode}${mobileInput}`,
      })
      .then((res) => {
        const { mobile, popup_text } = res.data;

        if (mobile === "invalid") {
          this.handleOpenAlertModal(popup_text, "error");
        } else {
          this.handleOpenAlertModal(
            "A sign-in link has been sent to your phone. This link will expire in 3 minutes.",
            "success"
          );
        }
      });
  };

  render() {
    const {
      mobileInput,
      loginAlert,
      isError,
      isLocked,
      remainingTime,
    } = this.state;

    const formattedRemainingTime = this.formatMilliseconds(remainingTime);

    return (
      <>
        <form
          className="login-using-mobile-form"
          onSubmit={this.handleRequestLink}
        >
          <div className="login-using-mobile-form__title">
            Sign in with mobile
          </div>

          <div className="login-using-mobile-form__input">
            <img
              className="login-using-mobile-form__img"
              src={sign_mobile_icon}
              alt="logo"
            />

            <PhoneNumberInput
              ref={this.mobileInputRef}
              name="mobile"
              placeholder="Mobile Number"
              onChange={this.handleChange}
              value={mobileInput}
              onChangeCountryCode={this.setSelectedCountryCode}
              setError={this.setError}
              fullWidth
            />
          </div>

          <button
            className="btn green--bg"
            disabled={Boolean(!mobileInput || isError)}
            onClick={this.handleRequestLink}
          >
            Request link
          </button>
        </form>

        <LoginAlertModal
          isOpen={loginAlert.isOpen}
          message={loginAlert.message}
          variant={loginAlert.variant}
          onClose={this.handleCloseAlertModal}
          buttonLabel={isLocked ? formattedRemainingTime : "OK"}
          buttonDisable={isLocked}
        />
      </>
    );
  }
}

export default LogInWithMobileForm;
