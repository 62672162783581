import React from "react";
import "./styles.scss";

const TextInput = (props) => {
  const {
    id,
    // label,
    type,
    placeholder = "",
    value,
    onChange,
    className = "",
    fullWidth,
    error,
  } = props;
  return (
    <div class={`input-field outlined ${fullWidth && "w-100"}`}>
      <input
        id={id || "outlined-input"}
        className={`outlined-input-field ${
          error ? "outlined-input-field--error" : ""
        } ${className}`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
      />
      {/* <label for="outlined-input">{label}</label> */}
    </div>
  );
};

export default TextInput;
